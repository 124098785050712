import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import PageCard from '../components/common/PageCard';

const Zdjecia = ({ data, location, pageContext }) => {
  const pages = data.allGhostPage.edges;

  return (
    <>
      <MetaData location={location} />
      <Layout isHome={true}>
          <section className="page-feed">
            {pages.map(({ node }) => (
              // The tag below includes the markup for each post - components/common/PostCard.js
              <PageCard key={node.id} post={node} />
            ))}
          </section>
      </Layout>
    </>
  );
};

Zdjecia.propTypes = {
  data: PropTypes.shape({
    allGhostPage: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.object,
};

export default Zdjecia;

export const pageQuery = graphql`
  query GhostPageQuery {
    allGhostPage(filter: {tags: {elemMatch: {name: {in: ["people", "pregnancy", "bachelorette"]}}}}, sort: {fields: published_at, order: DESC}) {
      edges {
        node {
          ...GhostPageFields
        }
      }
    }
  }
`;
