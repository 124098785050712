import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { getFriendlyTag } from '../../utils/getFriendlyTag'

const PageCard = ({ post }) => {
  const url = post.page ? `/${post.slug}/` : `/blog/${post.slug}/`;
  const tag = post.tags[0]?.name

  const friendlyTag = getFriendlyTag(tag)

  const imageStyle = post.feature_image ? {
    backgroundImage: `url(${post.feature_image})`,
  } : {};

  return (
    <Link to={url} className="page-card">
      <div
        className="page-card-image"
        style={imageStyle}
      >
        <div className="page-card-description">
          {friendlyTag && <p className="label">{friendlyTag}</p>}
          <h2 className="page-card-title">{post.title}</h2>
        </div>
      </div>
    </Link>
  );
};

PageCard.propTypes = {
  post: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    feature_image: PropTypes.string,
  }).isRequired,
};

export default PageCard;
